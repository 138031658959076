<template>
    <CustomTable
        id_table="choix_accountingplan"
        primaryKey="accountingplan_id"
		:items="items"
        :busy.sync="table_busy"
        :selected_ids.sync="selected_ids"
        :hrefsRoutes="config_table_hrefs"
		:selectMode="unique ? 'single' : 'multi'"
    />
</template>

<script type="text/javascript">
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'
    import Accounting from '@/mixins/Accounting.js'
    import Navigation from '@/mixins/Navigation.js'
    import Shutter from '@/mixins/Shutter.js'

	export default {
		name: "accountingPlanSelection",
        mixins:  [Accounting, Shutter, Navigation],
        props: {
			unique: { type: Boolean, default: false },
			code_onboarding: { type: String, default: '' }
        },
		data () {
			return {
                items: [],
                table_busy: false,

                config_table_hrefs: {
					'accountingplan_label': {
						routeUniqueName: 'AccountingInformations',
						routeName: 'AccountingInformations',
						params: {
							accountingplan_id: 'accountingplan_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},

                events_tab: {
					'TableAction::goToSelectAccountingPlan': this.onSelectAccountingPlan
				}
            }
		},
		mounted() {
			this.init_component()
		},
		methods: {
            async init_component() {
                this.table_busy = true
				this.items = await this.loadAccountingPlans()
				this.table_busy = false
            },
			async onSelectAccountingPlan(accountingplan_ids) {
				const cmp = {
					accountingaccount_create: 'components/Accounting/AccountingAccount',
					article_create: 'components/Accounting/Article',
					bank_create: 'components/Accounting/CheckConfig',
					pension_create: 'components/Accounting/Pension',
					link_acts_articles: 'components/Accounting/ActesTypeArticles'
				}

				if(this.code_onboarding) {
					const name = this.code_onboarding
					let props = {
						code_onboarding: this.code_onboarding,
						accountingplan_id: accountingplan_ids[0]
					}

					this.shutterPanel().open({
						name: name,
						title: this.code_onboarding,
						cmpPath: cmp[this.code_onboarding],
						props,
						onOk: this.closeShutters
					})
				}
			},
			closeShutters() {
				const params =  {
					code: this.code_onboarding,
					done: 1,
					skip: 0
				}

				this.ok(params)
				this.shutterPanel().close(this.code_onboarding + '_select')
				this.shutterPanel().close(this.code_onboarding)
			}
		},
		computed: {
			selected_ids: {
				get() {
					return this.selected_accountingplan_ids
				},
				set(val) {
					this.$emit('update:selected_accountingplan_ids', val)

					if(this.unique && val.length > 0) {
						this.onSelectAccountingPlan(val)
					}
				}
			}
		},
        components: {
			CustomTable,
			ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
		}
	}
</script>
